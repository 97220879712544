/*!
=========================================================
* Orderli Dashboard - v0.1.3
=========================================================
*/

import { getIdsFromQueryParams } from '@/plugins/processQueryParams';
// sentry setup
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/starterRouter'
import store from './store'
import DashboardPlugin from './plugins/dashboard-plugin'
// import BootstrapVue from 'bootstrap-vue'
// import VueCurrencyFilter from 'vue-currency-filter' //TODO FIND VUE 3 ALTERNATIVE
// import { configureCompat } from 'vue'

// NProgress
import '@/assets/css/nprogress.css';

// Tailwind for Shadcn
import '@/assets/index.css'

//Axios setup
import axios from 'axios'

// Reimplement Sentry at a later point when there are less errors
// if (import.meta.env.VITE_APP_SENTRY_DNS) {
//   Sentry.init({
//     Vue,
//     dsn: import.meta.env.VITE_APP_SENTRY_DNS,
//     autoSessionTracking: true,
//     ignoreErrors: ['ResizeObserver loop limit exceeded'],
//     integrations: [
//       new Integrations.BrowserTracing(),
//     ],
//     tracingOptions: {
//       trackComponents: true,
//     },
//     tracesSampleRate: 1.0,
//   });
// }

axios.defaults.baseURL = import.meta.env.VITE_APP_SERVER_URL;

const token = localStorage.getItem('jwt')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const UNAUTHORIZED = 401;

axios.interceptors.response.use(
  response => response,
  error => {
    Sentry.captureException(error)
    const {status} = error.response || {};
    if (status === UNAUTHORIZED) {
      store.dispatch('userShouldHaveBeenLoggedOut');
    }
    return Promise.reject(error);
  }
);

if(document.querySelector('meta[name="csrf-token"]')){
  axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

const app = createApp(App)

app.use(store)
app.use(router)
app.use(DashboardPlugin)
// app.use(BootstrapVue)
// app.use(VueCurrencyFilter) //TODO FIND VUE 3 ALTERNATIVE

// Run the initialization code once before mounting the app
let url = location.href;
let decodedUrl = decodeURIComponent(url);

// Fix Slack's incorrect encoding of ampersands
decodedUrl = decodedUrl.replace(/&amp;/g, '&');

let newQueryPath = getIdsFromQueryParams(store, new URL(decodedUrl).searchParams);
if (newQueryPath.hasIds) {
  router.replace({path: window.location.pathname, query: newQueryPath.query, hash: window.location.hash}).catch(() => {});
}

// Dispatch the onboarding status check
store.dispatch('checkOnboardingStatus');

axios.interceptors.request.use(
  req => {
    if(req.headers && req.headers.Authorization && req.method !== 'get' && store.getters.userEmail === 'ae@ycombinator.com' && req.url.substring(0,5) !== 'users' && req.url.substring(0,10) !== 'onboarding'){
      //Auth was required and not GET request
      alert('Sorry YC, we hacked together a quick read-only access of our dashboard in 10 minutes, so this action was blocked to prevent mutating our customers data.')
    }else if(store.getters.userEmail === 'ae@ycombinator.com' && req.url.includes('/orders/')  && !req.url.includes('/analytics/')){
      alert('Sorry YC, we disabled loading the order data, since this contains personal details of our customers\' guests and we cannot share that data due to European privacy laws.')
    }
    else{
      return req;
    }
  }
);

String.prototype.toTitleCase = function() {
  return this.split(' ').map(word =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
};

// configureCompat({
//   MODE: 3,
//   INSTANCE_ATTRS_CLASS_STYLE: false,
//   COMPONENT_V_MODEL: false,
//   GLOBAL_EXTEND: true,
//   GLOBAL_MOUNT: true,
//   INSTANCE_LISTENERS: false,
// })

app.mount('#app')