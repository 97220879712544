import axios from 'axios'

const untillAxios = axios.create({
  //   headers: {
  //     common: {'Authorization': `Basic ${btoa(auth)}`},
  //     AppToken: 'XXXXXXX:Orderli',
  // }
})

const untillAxios2 = axios.create({})

export const state = () => ({
  loading: false,
})

export const getters = {
  processingSoapRequest(state) {
    return state.loading
  },
}

export const actions = {
  listAvailableArticles({ dispatch, commit, getters, rootGetters }) {
    return new Promise((resolve, reject) => {
      untillAxios
        .get('http://testapi.untill.com:3064/api/v1/DEMO/article', {
          headers: {
            Authorization: `Basic ${btoa(
              `${rootGetters.currentRestaurant.pos_username}:${rootGetters.currentRestaurant.pos_password}`
            )}`,
            AppToken: `${rootGetters.currentRestaurant.appToken}:${rootGetters.currentRestaurant.appName}`,
          },
        })
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log('error')
          console.log(error)
          reject(error)
        })
    })
  },
  genericSoapRequest({ dispatch, commit, getters, rootGetters }, xmls) {
    commit('setLoadingState', true)
    return new Promise((resolve, reject) => {
      untillAxios2
        .post(rootGetters.currentRestaurant.pos_endpoint, xmls, {
          headers: { 'Content-Type': 'text/xml;charset=UTF-8' },
        })
        .then((res) => {
          console.log(res)
          commit('setLoadingState', false)
          resolve(res)
        })
        .catch((err) => {
          commit('setLoadingState', false)
          console.log(err)
          reject(err)
        })
    })
  },
}

export const mutations = {
  setLoadingState(state, payload) {
    state.loading = payload
  },
}
