/**
 * Check for accountId and restaurantId in query params and set them to the vuex store
 * Takes Vuex store as input argument
 */
export function getIdsFromQueryParams(store, URLSearchParams) {
  let query = {}
  let hasIds =
    URLSearchParams.has('accountId') && URLSearchParams.has('restaurantId')

  if (hasIds) {
    store.commit('setAccount', parseInt(URLSearchParams.get('accountId')))
    store.commit('setRestaurant', parseInt(URLSearchParams.get('restaurantId')))
    URLSearchParams.delete('accountId')
    URLSearchParams.delete('restaurantId')

    //Make a new object for the params, each 'entry' is a [key, value] tupple
    for (const [key, value] of URLSearchParams.entries()) {
      query[key] = value
    }
  }

  return { hasIds, query }
}
