import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index.js'
import DashboardLayout from '../views/Starter/Layout.vue';
import Starter from '../views/Pages/Dashboard.vue';
import AuthLayout from "@/views/Pages/AuthLayout";
import NotFound from "@/views/GeneralViews/NotFoundPage";
import NProgress from 'nprogress'
import { getIdsFromQueryParams } from '@/plugins/processQueryParams';

// Pages
// const User = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
// const TimeLine = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const ForgotPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/ForgotPassword.vue');
// const Home = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const ConfirmEmail = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/ConfirmEmail.vue');
const ResetPassword = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/ResetPassword.vue');
const Join = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Join.vue');
// const Lock = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');
const Onboarding = () => import(/* webpackChunkName: "pages" */ '@/views/Pages/Onboarding/Onboarding.vue');

// Products pages
const ItemsOverview = () => import(/* webpackChunkName: "products" */ '@/views/Products/ProductsOverview.vue');
const CategoriesOverview = () => import(/* webpackChunkName: "products" */ '@/views/Products/CategoriesOverview.vue');
const CardsOverview = () => import(/* webpackChunkName: "products" */ '@/views/Products/CardsOverview.vue');
const MenusOverview = () => import(/* webpackChunkName: "products" */ '@/views/Products/MenusOverview.vue');
const OptionsOverview = () => import(/* webpackChunkName: "products" */ '@/views/Products/OptionGroupsOverview.vue');
const RecommendationsOverview = () => import(/* webpackChunkName: "products" */ '@/views/Products/RecommendationsOverview.vue');

// Other dashboard pages
const Analytics = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Analytics.vue');
const MenuItemAnalytics = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/MenuItemAnalytics.vue');
const Inventory = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Inventory.vue');
const Orders = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Orders.vue');
const Payments = () => import('@/views/Pages/Payments.vue');
const SalesAreas = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/SalesAreas.vue');
const Devices = () => import(/* webpackChunkName: "dashboard" */ '@/views/Devices/Devices.vue');
const ActivityLog = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/ActivityLog.vue');
const Settings = () => import(/* webpackChunkName: "dashboard" */ '@/views/Settings/Settings.vue');
const Support = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Support.vue');
const TippingDashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/TippingDashboard.vue');

//Admin specific pages
const Admin = () => import(/* webpackChunkName: "admin" */ '@/views/Pages/Admin.vue');
const Actions = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/AdminActions.vue');
const Customers = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/CustomersOverview.vue');
const AdminAnalytics = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/AdminAnalytics.vue');
const Resellers = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/ResellersOverview.vue');
const SalesBonuses = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/SalesBonuses.vue');
const Subscriptions = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/ManageSubscriptions.vue');
const MethodPrices = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/ManageMethodPrices.vue');
const PSPSettings = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/PSPSettings.vue');
const QRPrinter = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/QRPrinter.vue');
const PosRequestLogger = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/PosRequestLogger.vue')
const FeatureFlags = () => import(/* webpackChunkName: "admin" */ '@/views/Admin/FeatureFlags.vue')

//Reseller specific pages
const Reseller = () => import(/* webpackChunkName: "reseller" */ '@/views/Resellers/ResellerAdmin.vue');

//Redirect pages
const MandateCompletion = () => import(/* webpackChunkName: "redirects" */ '@/views/Settings/MandateCompletion.vue')
const OAuth = () => import(/* webpackChunkName: "redirects" */ '@/views/Settings/OAuth.vue')

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  meta: { hideOnAuth: true },
  children: [
    // {
    //   path: '/home',
    //   name: 'Home',
    //   component: Home,
    //   meta: {
    //     noBodyBackground: true
    //   }
    // },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/forgot-password',
      name: 'Forgot Password',
      component: ForgotPassword
    },
    {
      path: '/register',
      name: 'Register',
      component: Register
    },
    {
      path: '/confirm/:id',
      name: 'Confirm Email',
      component: ConfirmEmail
    },
    {
      path: '/verify/:id',
      name: 'Verify Email',
      component: ConfirmEmail
    },
    {
      path: '/reset/:id',
      name: 'Reset Password',
      component: ResetPassword
    },
    {
      path: '/join/:id',
      name: 'Join Orderli',
      component: Join
    },
    // {
    //   path: '/user',
    //   name: 'User',
    //   component: User
    // },
    // {
    //   path: '/timeline',
    //   name: 'TimeLine',
    //   component: TimeLine
    // }
  ]
};

let onboardingPages = {
  path: '/onboarding',
  component: Onboarding,
  name: 'Onboarding',
  meta: { requiresUncompletedOnboarding: true },
};

let productsMenu = {
  path: '/menu',
  component: DashboardLayout,
  redirect: '/menu/menus',
  name: 'Menu',
  meta: { requiresAuth: true },
  children: [
    {
      path: 'menus',
      name: 'Menu Editor',
      component: MenusOverview
    },
    {
      path: 'cards',
      name: 'Cards',
      component: CardsOverview
    },
    {
      path: 'categories',
      name: 'Categories',
      component: CategoriesOverview
    },
    {
      path: 'items',
      name: 'Items',
      component: ItemsOverview,
    },
    {
      path: 'modifiers',
      name: 'Option Groups',
      component: OptionsOverview
    },
    {
      path: 'recommendations',
      name: 'Recommendations',
      component: RecommendationsOverview
    }
  ]
};

let router = createRouter({
  history: createWebHistory(),
  routes: [
    productsMenu,
    {
      path: '/',
      name: 'home',
      meta: { requiresAuth: true },
      redirect: '/dashboard',
      component: DashboardLayout,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          components: { default: Starter },
        },
        {
          path: '/analytics',
          name: 'analytics',
          component: Analytics,
        },
        {
          path: '/analytics/item/:id',
          name: 'Menu item analytics',
          component: MenuItemAnalytics,
          props: route => ({startDate: route.query.startDate, endDate: route.query.endDate})
        },
        {
          path: '/orders',
          name: 'orders',
          component: Orders,
        },
        {
          path: '/payments',
          name: 'payments',
          component: Payments,
        },
        {
          path: '/qrs',
          name: 'QR Codes',
          component: SalesAreas,
        },
        {
          path: '/inventory',
          name: 'inventory',
          component: Inventory,
        },
        {
          path: '/devices',
          name: 'devices',
          component: Devices,
        },
        // {
        //   path: '/payments',
        //   name: 'payments',
        //   component: Payments,
        // },
        {
          path: '/settings/mandate-completion',
          name: 'Mandate completion',
          component: MandateCompletion,
        },
        {
          path: '/oauth/:service',
          name: 'OAuth completion',
          component: OAuth,
        },
        {
          path: '/activity-log',
          name: 'Activity Log',
          component: ActivityLog,
        },
        {
          path: '/settings',
          name: 'settings',
          component: Settings,
        },
        {
          path: '/support',
          name: 'Support',
          component: Support,
        },
        {
          path: '/tips',
          name: 'My tips',
          component: TippingDashboard,
        },
        {
          path: '/reseller',
          name: 'Partner Portal',
          component: Reseller,
        },
        {
          path: '/admin',
          component: Admin,
          meta: {adminOnly: true},
          children: [
            { path: '', name: 'admin', component: Actions },
            { path: 'customers', name: 'Admin Customers', component: Customers },
            { path: 'analytics', name: 'Admin Analytics', component: AdminAnalytics },
            { path: 'resellers', name: 'Admin Resellers', component: Resellers },
            { path: 'sales-bonuses', name: 'Admin Sales Bonuses', component: SalesBonuses },
            { path: 'subscriptions', name: 'Admin Subscriptions', component: Subscriptions },
            { path: 'method-prices', name: 'Admin Method Prices', component: MethodPrices },
            { path: 'psp-settings', name: 'Admin PSP Settings', component: PSPSettings },
            { path: 'qr-printer', name: 'Admin QR Printer', component: QRPrinter },
            { path: 'pos-request-logger', name: 'Admin POS Request Logger', component: PosRequestLogger },
            { path: 'feature-flags', name: 'Admin Feature Flags', component: FeatureFlags },
          ]
        }
      ]
    },
    authPages,
    onboardingPages,
    {
      path: '/reports/settlement/:id',
      name: 'Settlement Report',
      beforeEnter: (to, from, next) => {
        next({ path: '/settings', hash: '#payments', replace: true });
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Not Found',
      component: NotFound
    }
  ],
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

function checkOnboarding(next, nextPath){
  let onboardingPath = nextPath ? undefined : '/onboarding'; //set onboardingPath to null if nextPath exists, because it is only used by onboarding pages
  if(store.getters.onboardingCompleted || store.getters.isAdmin){
    next(nextPath)
  }else if(store.getters.onboardingCompleted === undefined){
    const watcher = store.watch(() => store.getters.onboardingCompleted, onboardingCompleted => {
      watcher(); // stop watching
      if (onboardingCompleted){
        next(nextPath);
      }
      else{
        if(store.getters.isAdmin === undefined){
          const watcher = store.watch(() => store.getters.isAdmin, serverCallCompleted => {
            watcher();
            if (serverCallCompleted) next(nextPath);
            else next (onboardingPath)
          })
        }else if(store.getters.isAdmin){
          next(nextPath);
        }else{
          next(onboardingPath);
        }
      }
    });
  }else if(store.getters.isAdmin === undefined){
    console.log('hier6')
    const watcher = store.watch(() => store.getters.isAdmin, serverCallCompleted => {
      watcher();
      if (serverCallCompleted) next(nextPath);
      else next (onboardingPath)
    })
  }
  else{
    console.log('hier7')
    next(onboardingPath)
  }
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  NProgress.set(0.1)

  if(to.matched.some(record => record.meta.requiresAuth) && !import.meta.env.VITE_APP_SKIP_ROUTING) {
    //Logic to prevent user accessing pages with meta requiresAuth true
    if (store.getters.isLoggedIn) {
      checkOnboarding(next);
    }else if(store.getters.isLoggedIn === undefined){
      //TODO add timeout to this and the others
      const watcher = store.watch(() => store.getters.isLoggedIn, isLoggedIn => {
        watcher(); // stop watching
        if (isLoggedIn) {
          checkOnboarding(next);
        }
        else next({path: '/login', query: {nextUrl: to.fullPath + window.location.search}});
      });
    }else{
      next({path: '/login', query: {nextUrl: to.fullPath + window.location.search}})
    }
  }
  else if(to.matched.some(record => record.meta.hideOnAuth) && !import.meta.env.VITE_APP_SKIP_ROUTING){
    //Logic to prevent user accessing authentication pages (login, register, join, etc.) when already logged in

    const handleRedirect = () => {
      // Determine which URL the user should be redirected to when already logged in
      let nextUrl = to.query.nextUrl || '/';
      let decodedNextUrl = decodeURIComponent(nextUrl);
      let newQueryPath = getIdsFromQueryParams(store, new URL(decodedNextUrl, window.location.origin).searchParams);
      if (newQueryPath.hasIds) {
        router.replace({path: new URL(decodedNextUrl, window.location.origin).pathname, query: newQueryPath.query, hash: new URL(decodedNextUrl, window.location.origin).hash}).catch(() => {});
      }
      next({ path: new URL(decodedNextUrl, window.location.origin).pathname, query: newQueryPath.query, hash: new URL(decodedNextUrl, window.location.origin).hash });
    };

    if (store.getters.isLoggedIn) {
      // Check if the user is logged in and redirect accordingly
      handleRedirect();
    } 
    else if (store.getters.isLoggedIn === undefined) {
      // Watch for changes in the isLoggedIn state
      const watcher = store.watch(() => store.getters.isLoggedIn, isLoggedIn => {
        watcher(); // stop watching
        if (isLoggedIn) {
          handleRedirect();
        } else {
          next();
        }
      });
    }
    else {
      // If the user is not logged in, continue with the route
      next();
    }

  }
  else if(to.matched.some(record => record.meta.requiresUncompletedOnboarding) && !import.meta.env.VITE_APP_SKIP_ROUTING){
    //Logic to prevent user accessing onboarding pages without being logged in
    if (store.getters.isLoggedIn) {
      checkOnboarding(next, '/');
    }else if(store.getters.isLoggedIn === undefined){
      const watcher = store.watch(() => store.getters.isLoggedIn, isLoggedIn => {
        watcher(); // stop watching
        if (isLoggedIn) {
          checkOnboarding(next, '/');
        }
        else next('/login');
      })
    }else{
      next('/login')
    }
  }
  else if(to.matched.some(record => record.meta.adminOnly) && !import.meta.env.VITE_APP_SKIP_ROUTING){
    //Logic to prevent user accessing admin pages without being admin
    if (store.getters.isAdmin) {
      next()
    }else if(store.getters.isAdmin === undefined){
      const watcher = store.watch(() => store.getters.isAdmin, isAdmin => {
        watcher(); // stop watching
        if (isAdmin) {
          next()
        }
        else next('/');
      })
    }else{
      next('/')
    }
  }
  else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done();
})

export default router;
