<template>
  <Popover v-model:open="open">
    <PopoverTrigger as-child>
      <Button
        variant="outline"
        role="combobox"
        :aria-expanded="open"
        :class="['w-full justify-between', { 'pl-1 pr-2': showLogo }]"
      >
        <div class="flex items-center">
          <div v-if="selectedLocation?.logo" 
            class="h-7 flex-shrink-0 transition-all duration-500 ease-in-out"
            :class="[ showLogo ? 'w-7 mr-2' : 'w-0 mr-0' ]"
          >
            <img 
              :src="`https://imagedelivery.net/xS_5nksgKmcoB2_mcBGUmA/${selectedLocation.logo}/public`"
              class="w-full h-full rounded-sm object-contain"
              :alt="selectedLocation.name"
              @load="onLogoLoad"
              @error="onLogoError"
              v-show="showLogo"
            />
          </div>
          <span :title="selectedLocation ? selectedLocation.name : 'Select location...'" class="text-left truncate flex-grow">
            {{ selectedLocation ? selectedLocation.name : "Select location..." }}
          </span>
        </div>
        <CaretSortIcon class="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    </PopoverTrigger>
    <PopoverContent class="p-0 w-100" align="start">
      <Command :filter="filterFunction">
        <div class="flex justify-between items-center border-b">
          <CommandInput 
            class="h-9 flex-grow" 
            wrapper-class="border-none"
            placeholder="Search location..." 
            @input="updateSearchTerm"
          />
          <only admins>
            <div class="ml-auto flex items-center">
              <Label for="show-hidden" class="text-xs text-muted-foreground mb-0 mr-1">Show hidden</Label>
              <Switch id="show-hidden" v-model:checked="showHidden" class="mr-2" />
            </div>
          </only>
        </div>
        
        <CommandEmpty>No location found.</CommandEmpty>
        <CommandList class="max-h-[50vh] overflow-y-auto" ref="commandListRef">
          <template v-for="(account, index) in filteredAccounts" :key="account.id">
            <CommandGroup class="pb-2">
              <ComboboxLabel class="flex items-center px-2 py-2 text-xs font-medium text-muted-foreground">
                <span :title="getAccountLabel(account)" class="truncate inline-block max-w-[300px]" v-html="highlightMatch(getAccountLabel(account), searchTerm)"></span>
                <span class="ml-2 text-stone-400 text-xs">
                  <span v-html="highlightMatch(account.id.toString(), searchTerm)"></span>
                </span>
              </ComboboxLabel>
              <CommandItem
                v-for="restaurant in account.restaurants"
                :key="`${account.id}-${restaurant.id}`"
                :value="`${account.id},${restaurant.id},${account.name},${restaurant.name}`"
                :class="{'text-muted-foreground opacity-40': restaurant.deleted}"
                @select="handleSelect(restaurant.id, account.id)"
                :ref="el => { if (isSelected(account.id, restaurant.id)) selectedItemRef = el }"
              >
                <span 
                  :title="getRestaurantLabel(restaurant)"
                  class="text-left truncate inline-block max-w-[300px]"
                  :class="{'selected-item': isSelected(account.id, restaurant.id)}"
                  v-html="highlightMatch(getRestaurantLabel(restaurant), searchTerm)"
                ></span>
                <span class="ml-auto text-stone-400 text-xs">
                  <span v-html="highlightMatch(restaurant.id.toString(), searchTerm)"></span>
                </span>
              </CommandItem>
              <CommandItem
                v-if="account.restaurants.length === 0"
                :value="`${account.id},null,${account.name},null`"
                class="text-stone-400"
                @select="handleSelect(null, account.id)"
              >
                No locations found
              </CommandItem>
              <div v-if="index < filteredAccounts.length - 1" class="border-b border-stone-200 mt-2 mx-2"></div>
            </CommandGroup>
          </template>
        </CommandList>
      </Command>
    </PopoverContent>
  </Popover>
</template>

<script>
import { defineComponent, ref, computed, watch, nextTick } from 'vue'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { ComboboxLabel } from 'radix-vue'
import { CaretSortIcon } from '@radix-icons/vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'LocationSelector',
  components: {
    Popover, PopoverContent, PopoverTrigger, Button, Switch, Label, Command, CommandEmpty,
    CommandGroup, CommandInput, CommandItem, CommandList, CaretSortIcon, ComboboxLabel
  },
  setup() {
    const store = useStore()
    const open = ref(false)
    const searchTerm = ref('')
    const showHidden = ref(false)
    const showLogo = ref(false)
    const selectedItemRef = ref(null)
    const commandListRef = ref(null)

    const canSeeEmptyAccounts = computed(() => {
      return store.getters.canManageAccounts && showHidden.value
    })

    const accounts = computed(() => {
      return store.state.accounts.filter(
        (acc) =>
          (acc.name !== null || acc.restaurants.length > 0) &&
          (canSeeEmptyAccounts.value ||
            (acc.subscription !== null &&
              acc.subscription.database_name !== 'inactive'))
      )
    })

    const filteredAccounts = computed(() => {
      return accounts.value.map(acc => {
        const filteredRestaurants = showHidden.value
          ? acc.restaurants
          : acc.restaurants.filter(res => !res.deleted);
        
        return {
          ...acc,
          restaurants: filteredRestaurants
        };
      }).filter(acc => acc.restaurants.length > 0 || canSeeEmptyAccounts.value);
    })

    const selected = computed({
      get() {
        return JSON.stringify({
          restaurantId: store.state.auth.restaurantId,
          accountId: store.state.auth.accountId,
        })
      },
      set(value) {
        const { accountId, restaurantId } = JSON.parse(value)
        store.commit('setAccount', accountId)
        store.commit('setRestaurantWithReload', restaurantId)
      },
    })

    const selectedLocation = computed(() => {
      const { accountId, restaurantId } = JSON.parse(selected.value)
      const account = accounts.value.find(acc => acc.id === accountId)
      if (!account) return null
      const restaurant = account.restaurants.find(res => res.id === restaurantId)
      if (!restaurant) return { name: account.name, logo: null }
      return { name: restaurant.name, logo: restaurant.logo }
    })

    watch(selectedLocation, () => {
      showLogo.value = false;
    });

    watch(open, (newValue) => {
      if (newValue) {
        nextTick(() => {
          setTimeout(() => {
            scrollToSelectedItem();
          }, 1);
        });
      }
    });

    const scrollToSelectedItem = () => {
      console.log("Attempting to scroll to selected item");
      if (commandListRef.value && selectedItemRef.value) {
        const listElement = commandListRef.value.$el;
        const selectedElement = selectedItemRef.value.$el;
        
        if (listElement && selectedElement) {
          const parentGroup = selectedElement.closest('[role="group"]');
          if (!parentGroup) {
            console.log("Parent group not found");
            return;
          }

          const listHeight = listElement.clientHeight;
          const selectedElementHeight = selectedElement.offsetHeight;
          const parentGroupHeight = parentGroup.offsetHeight;
          const parentGroupTop = parentGroup.offsetTop - listElement.offsetTop;

          let scrollTop;

          // Check if aligning to the top of the parent group would keep the selected element in view
          if (parentGroupHeight <= listHeight) {
            // Align to the top of the parent group
            scrollTop = parentGroupTop;
          } else {
            // Calculate how many items can fit in view
            const itemsInView = Math.floor(listHeight / selectedElementHeight);
            const selectedIndex = Array.from(parentGroup.children).indexOf(selectedElement);
            
            if (selectedIndex < itemsInView) {
              // Align to the top of the parent group
              scrollTop = parentGroupTop;
            } else {
              // Align the selected element to the bottom of the view
              scrollTop = selectedElement.offsetTop - listElement.offsetTop - listHeight + selectedElementHeight;
            }
          }

          console.log("Calculated scrollTop:", scrollTop);
          listElement.scrollTop = Math.max(0, scrollTop);
          console.log("Scrolled to position:", listElement.scrollTop);

          // Additional check for visibility
          const isVisible = (
            selectedElement.offsetTop >= listElement.scrollTop &&
            selectedElement.offsetTop + selectedElementHeight <= listElement.scrollTop + listHeight
          );
          console.log("Is selected element visible?", isVisible);
        } else {
          console.log("Either listElement or selectedElement is null");
        }
      } else {
        console.log("Either commandListRef or selectedItemRef is null");
      }
    };

    return {
      open,
      accounts,
      filteredAccounts,
      selected,
      selectedLocation,
      searchTerm,
      showHidden,
      showLogo,
      selectedItemRef,
      commandListRef,
      scrollToSelectedItem
    }
  },
  methods: {
    filterFunction(value, search) {
      if (!search) return 1;
      const searchLower = search.toLowerCase();
      const [accountId, restaurantId, accountName, restaurantName] = value.split(',');

      const matchesSearch = (str) => str && str.toLowerCase().includes(searchLower);

      if (!this.showHidden && restaurantId && this.filteredAccounts.some(acc => acc.restaurants.some(res => res.id === parseInt(restaurantId) && res.deleted))) {
        return 0;
      }

      return (matchesSearch(accountId) || 
              matchesSearch(restaurantId) || 
              matchesSearch(accountName) || 
              matchesSearch(restaurantName)) ? 1 : 0;
    },

    handleSelect(restaurantId, accountId) {
      this.selected = JSON.stringify({
        restaurantId: restaurantId,
        accountId: accountId,
      });
      this.open = false;
    },

    updateSearchTerm(event) {
      this.searchTerm = event.target.value;
    },

    highlightMatch(text, term) {
      if (!term) return text;
      const regex = new RegExp(`(${term})`, 'gi');
      return text.replace(regex, '<span class="bg-yellow-200">$1</span>');
    },

    isSelected(accountId, restaurantId) {
      const selectedValue = JSON.parse(this.selected);
      return selectedValue.accountId === accountId && selectedValue.restaurantId === restaurantId;
    },

    getAccountLabel(account) {
      if (!account.name) return 'null'
      if (!this.$store.getters.canManageAccounts) return account.name
      
      const status = account.completed ? '✅ ' : '🚧 '
      const subscription = account.subscription ? account.subscription.name : '⚠️ No subscription'
      
      return `${status}${account.name} (${subscription})`
    },

    getRestaurantLabel(restaurant) {
      if (restaurant.deleted) return `🗑 ${restaurant.name} (DELETED)`
      if (restaurant.closed) return `🔒 ${restaurant.name} (closed)`
      return restaurant.name
    },

    onLogoLoad() {
      this.showLogo = true;
    },

    onLogoError() {
      this.showLogo = false;
    },
  },
})
</script>

<style scoped>
.selected-item {
  color: hsl(var(--orderli));
  font-weight: bold;
}

:deep(.button) {
  width: 100%;
  max-width: 100%;
}
</style>