import axios from 'axios'
import * as Vue from 'vue'
import localForage from 'localforage'
// import NotificationStore from '@/components/NotificationPlugin/index.js'
import { toast } from 'vue-sonner'

export const state = () => ({
  menu: {
    menus: [],
    cards: [],
    categories: [],
    items: [],
    option_groups: [],
    recommendations: [],
  },
  inactiveItems: [],
  posItems: null,
  itemTypes: null,
  menuBuildup: {
    menus: [],
    cards: [],
    categories: [],
    items: [],
    option_groups: [],
    recommendations: [],
  },
  menuHasLoaded: false,
  menuIsLoading: false,
  inactiveItemsHaveLoaded: false,
  inactiveItemsIsLoading: false,
  showItemModalId: null,
  quickSelectOptions: [
    {
      label: 'Drinks',
      options: [
        {
          value: 'beer',
          label: 'Beer',
        },
        {
          value: 'beer-glass',
          label: 'Beer glass',
        },
        {
          value: 'pint',
          label: 'Pint',
        },
        {
          value: 'beer-tap',
          label: 'Beer tap',
        },
        {
          value: 'beer-can',
          label: 'Beer can',
        },
        {
          value: 'bottle-cap',
          label: 'Bottle cap',
        },
        {
          value: 'champagne',
          label: 'Champagne',
        },
        {
          value: 'cocktail',
          label: 'Cocktail',
        },
        {
          value: 'cocktail-2',
          label: 'Cocktail alternative',
        },
        {
          value: 'martini-glass',
          label: 'Martini Glass',
        },
        {
          value: 'liquor',
          label: 'Liquor',
        },
        {
          value: 'moonshine',
          label: 'Moonshine',
        },
        {
          value: 'whiskey-bottle',
          label: 'Whiskey Bottle',
        },
        {
          value: 'whiskey',
          label: 'Whiskey Glass',
        },
        {
          value: 'shot',
          label: 'Shot',
        },
        {
          value: 'wine',
          label: 'Wine',
        },
        {
          value: 'wine-bottle',
          label: 'Wine Bottle',
        },
        {
          value: 'no-alcohol',
          label: 'No alcohol',
        },
        {
          value: 'hot-drinks',
          label: 'Hot Drinks',
        },
        {
          value: 'tea',
          label: 'Tea',
        },
        {
          value: 'soda',
          label: 'Soda',
        },
        {
          value: 'soda-2',
          label: 'Soda Alternative',
        },
        {
          value: 'lemonade',
          label: 'Lemonade',
        },
        {
          value: 'juice',
          label: 'Juice',
        },
        {
          value: 'milk',
          label: 'Milk',
        },
        {
          value: 'refreshments',
          label: 'Refreshments',
        },
        {
          value: 'water-tap',
          label: 'Water tap',
        },
        {
          value: 'energy-drink',
          label: 'Energy Drink',
        },
        {
          value: 'coffee-beans',
          label: 'Coffee Beans',
        },
        {
          value: 'coffee-to-go',
          label: 'Coffee To Go',
        },
      ],
    },
    {
      label: 'Food',
      options: [
        {
          value: 'baguette',
          label: 'Baguette',
        },
        {
          value: 'bread',
          label: 'Bread',
        },
        {
          value: 'pancakes',
          label: 'Pancakes',
        },
        {
          value: 'cake',
          label: 'Cake',
        },
        {
          value: 'pie',
          label: 'Pie',
        },
        {
          value: 'cookie',
          label: 'Cookie',
        },
        {
          value: 'croissant',
          label: 'Croissant',
        },
        {
          value: 'sweets',
          label: 'Sweets',
        },
        {
          value: 'sandwich',
          label: 'Sandwich',
        },
        {
          value: 'pizza',
          label: 'Pizza',
        },
        {
          value: 'salad',
          label: 'Salad',
        },
        {
          value: 'ricebowl',
          label: 'Ricebowl',
        },
        {
          value: 'noodles',
          label: 'Noodles',
        },
        {
          value: 'soup',
          label: 'Soup',
        },
        {
          value: 'chickenbox',
          label: 'Chicken Box',
        },
        {
          value: 'fried_rice',
          label: 'Fried Rice',
        },
        {
          value: 'fondue',
          label: 'Fondue',
        },
        {
          value: 'tapas',
          label: 'Tapas',
        },
        {
          value: 'eggs',
          label: 'Eggs',
        },
        {
          value: 'spaghetti',
          label: 'Spaghetti',
        },
        {
          value: 'sushi',
          label: 'Sushi',
        },
        {
          value: 'taco',
          label: 'Taco',
        },
        {
          value: 'wrap',
          label: 'Wrap',
        },
        {
          value: 'ham',
          label: 'Ham',
        },
        {
          value: 'meat',
          label: 'Meat',
        },
        {
          value: 'cow-beef',
          label: 'Cow/beef',
        },
        {
          value: 'seafood',
          label: 'Seafood',
        },
        {
          value: 'fish',
          label: 'Fish',
        },
        {
          value: 'paella',
          label: 'Paella',
        },
        {
          value: 'turkey',
          label: 'Turkey',
        },
        {
          value: 'poultry',
          label: 'Poultry',
        },
        {
          value: 'chicken',
          label: 'Chicken',
        },
        {
          value: 'lamb-rack',
          label: 'Lamb rack',
        },
        {
          value: 'kebab',
          label: 'Kebab',
        },
        {
          value: 'fries',
          label: 'Fries',
        },
        {
          value: 'bitterballen',
          label: 'Bitterballen',
        },
        {
          value: 'burger',
          label: 'Burger',
        },
        {
          value: 'hamburger',
          label: 'Burger Alternative',
        },
        {
          value: 'hotdog',
          label: 'Hot dog',
        },
        {
          value: 'nachos',
          label: 'Nachos',
        },
        {
          value: 'cheese',
          label: 'Cheese ',
        },
        {
          value: 'refreshments',
          label: 'Refreshments',
        },
        {
          value: 'icecream',
          label: 'Ice cream',
        },
        {
          value: 'popsicle',
          label: 'Popsicle',
        },
      ],
    },
    {
      label: 'Miscellaneous',
      options: [
        {
          value: 'chocolate',
          label: 'Chocolate',
        },
        {
          value: 'candy',
          label: 'Candy',
        },
        {
          value: 'fresh',
          label: 'Fresh',
        },
        {
          value: 'vegetarian',
          label: 'Vegetarian',
        },
        {
          value: 'chili-pepper',
          label: 'Chili pepper',
        },
        {
          value: 'flame',
          label: 'Flame',
        },
        {
          value: 'water',
          label: 'Water',
        },
        {
          value: 'explosion',
          label: 'Explosion',
        },
        {
          value: 'bomb',
          label: 'Bomb',
        },
        {
          value: 'flower',
          label: 'Flower',
        },
        {
          value: 'dumbbell',
          label: 'Dumbbell',
        },
        {
          value: 'toothbrush',
          label: 'Toothbrush',
        },
        {
          value: 'bathtub',
          label: 'Bathtub',
        },
        {
          value: 'headphones',
          label: 'Headphones',
        },
        {
          value: 'rubber-duck',
          label: 'Rubber duck',
        },
        {
          value: 'disco-ball',
          label: 'Disco ball',
        },
        {
          value: 'confetti',
          label: 'Confetti',
        },
        {
          value: 'gift',
          label: 'Gift',
        },
        {
          value: 'balloons',
          label: 'Balloons',
        },
        {
          value: 'eye',
          label: 'Eye',
        },
        {
          value: 'sharing',
          label: 'Sharing',
        },
        {
          value: 'olive-oil',
          label: 'Olive oil',
        },
        {
          value: 'halal',
          label: 'Halal',
        },
        {
          value: '1',
          label: '1',
        },
        {
          value: '2',
          label: '2',
        },
        {
          value: '3',
          label: '3',
        },
        {
          value: '4',
          label: '4',
        },
        {
          value: '5',
          label: '5',
        },
      ],
    },
  ],
  categoryStyles: [
    {
      value: 'single',
      label: 'Standard',
    },
    {
      value: 'double',
      label: 'Double',
    },
    {
      value: 'message',
      label: 'Message',
    },
    {
      value: 'reservation',
      label: 'Reservation',
    },
    {
      value: 'spin-wheel',
      label: 'Spinner'
    },
    {
      value: 'welcome-message',
      label: 'Welcome message (beta)',
    },
    {
      value: 'welcome-message-v2',
      label: 'Welcome message (beta v2)',
    },
    {
      value: 'single-scaleimage',
      label: 'Standard - Scale images',
    },
    {
      value: 'single-banner',
      label: 'Standard - Banner',
    },
    {
      value: 'banner',
      label: 'Banner',
    },
    {
      value: 'double-banner',
      label: 'Double - Banner',
    },
    {
      value: 'image-only',
      label: 'Image only (beta)',
    },
  ],
  optionGroupTypeOptions: [
    {
      value: 'Select',
      label: 'Select',
      icon: 'fas fa-fw fa-circle-check',
    },
    {
      value: 'Addons',
      label: 'Addons',
      icon: 'fas fa-fw fa-square-check',
    },
    {
      value: 'Glasses',
      label: 'Glasses',
      icon: 'fas fa-fw fa-champagne-glasses',
    },
  ],
})

export const getters = {
  menuIsLoading(state) {
    return state.menuIsLoading || !state.menuHasLoaded
  },
  inactiveItemsIsLoading(state) {
    if (state.inactiveItemsIsLoading) {
      return true
    } else {
      return false
    }
  },
  inactiveItemsHaveLoaded(state) {
    if (state.inactiveItemsHaveLoaded) {
      return true
    } else {
      return false
    }
  },
  menusOfCurrentRestaurant(state, getters) {
    if (getters.menuIsLoading) return []
    return state.menu.menus
      .filter((x) => x.restaurant_id === getters.currentRestaurantId)
      .sort((a, b) => a.id - b.id)
  },
  cardsOfCurrentRestaurant(state, getters) {
    if (getters.menuIsLoading) return []
    return state.menu.cards
      .filter((x) => x.restaurant_id === getters.currentRestaurantId)
      .sort((a, b) => a.id - b.id)
  },
  categoriesOfCurrentRestaurant(state, getters) {
    if (getters.menuIsLoading) return []
    return state.menu.categories
      .filter((x) => x.restaurant_id === getters.currentRestaurantId)
      .sort((a, b) => a.id - b.id)
  },
  itemsOfCurrentRestaurant(state, getters) {
    if (getters.menuIsLoading) return []

    let items = state.menu.items.filter(
      (x) => x.restaurant_id === getters.currentRestaurantId && !x.is_option
    )
    let inactiveItems = state.inactiveItems.filter(
      (x) => x.restaurant_id === getters.currentRestaurantId && !x.is_option
    )
    return items.concat(inactiveItems).sort((a, b) => a.id - b.id)
  },
  optionItemsOfCurrentRestaurant(state, getters) {
    if (getters.menuIsLoading) return []
    else
      return state.menu.items
        .filter(
          (x) => x.restaurant_id === getters.currentRestaurantId && x.is_option
        )
        .sort((a, b) => a.id - b.id)
  },
  itemsAndOptionItemsOfCurrentRestaurant(state, getters) {
    if (getters.menuIsLoading) return []
    else
      return state.menu.items
        .filter((x) => x.restaurant_id === getters.currentRestaurantId)
        .sort((a, b) => a.id - b.id)
  },
  optionGroupsOfCurrentRestaurant(state, getters) {
    if (getters.menuIsLoading) return []
    else
      return state.menu.option_groups
        .filter((x) => x.restaurant_id === getters.currentRestaurantId)
        .sort((a, b) => a.id - b.id)
  },
  recommendationsOfCurrentRestaurant(state, getters) {
    if (!getters.menuIsLoading) return []
    else if (!Array.isArray(state.menu.recommendations)) return []
    else
      return state.menu.recommendations
        .filter((x) => x.restaurant_id === getters.currentRestaurantId)
        .sort((a, b) => a.id - b.id)
  },
  recommendationsNotSupportedForSubscription(state, getters) {
    if (!getters.menuIsLoading) return false
    return !Array.isArray(state.menu.recommendations)
  },
  inactiveItemsOfCurrentRestaurant(state, getters) {
    if (getters.inactiveItemsIsLoading) return []
    else
      return state.inactiveItems
        .filter((x) => x.restaurant_id === getters.currentRestaurantId)
        .sort((a, b) => a.id - b.id)
  },
  menusMap(state, getters) {
    return new Map(
      getters.menusOfCurrentRestaurant.map((element) => [element.id, element])
    )
  },
  cardsMap(state, getters) {
    return new Map(
      getters.cardsOfCurrentRestaurant.map((element) => [element.id, element])
    )
  },
  categoriesMap(state, getters) {
    return new Map(
      getters.categoriesOfCurrentRestaurant.map((element) => [
        element.id,
        element,
      ])
    )
  },
  itemsMap(state, getters) {
    return new Map(
      getters.itemsOfCurrentRestaurant.map((element) => [element.id, element])
    )
  },
  optionItemsMap(state, getters) {
    return new Map(
      getters.optionItemsOfCurrentRestaurant.map((element) => [
        element.id,
        element,
      ])
    )
  },
  itemsAndOptionsMap(state, getters) {
    return new Map(
      getters.itemsAndOptionItemsOfCurrentRestaurant
        .concat(getters.inactiveItemsOfCurrentRestaurant)
        .map((element) => [element.id, element])
    )
  },
  itemsAndOptionsPosIdMap(state, getters) {
    return new Map(
      getters.itemsAndOptionItemsOfCurrentRestaurant
        .concat(getters.inactiveItemsOfCurrentRestaurant)
        .map((element) => [element.pos_id, element])
    )
  },
  optionGroupsMap(state, getters) {
    return new Map(
      getters.optionGroupsOfCurrentRestaurant.map((element) => [
        element.id,
        element,
      ])
    )
  },
  posItems(state) {
    return state.posItems
  },
}

export const actions = {
  updateCardsOrder({ commit, rootState }, payload) {
    commit('updateCardsOrderAtId', {
      id: payload.id,
      value: payload.orderedArray.map((cardObject) => cardObject.id),
    })

    //Also update the order on the server
    let menus = []
    menus.push({
      [payload.id]: payload.orderedArray.map((cardObject) => ({
        id: cardObject.id,
      })),
    })

    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/menus/cards`,
          { menus: menus }
        )
        .then(function (response) {
          console.log(response)
          toast.success('Cards order updated')
          resolve(response)
        })
        .catch(function (error) {
          //TODO implement errors
          console.log(error)
          reject(error)
        })
    })
  },
  updateCategoriesOrder({ commit, rootState }, payload) {
    commit('updateCategoriesOrderAtId', {
      id: payload.id,
      value: payload.orderedArray.map((categoryObject) => categoryObject.id),
    })

    //Also update the order on the server
    let cards = []
    cards.push({
      [payload.id]: payload.orderedArray.map((categoryObject) => ({
        id: categoryObject.id,
      })),
    })

    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/cards/categories`,
          { cards: cards }
        )
        .then(function (response) {
          console.log(response)
          toast.success('Categories order updated')
          resolve(response)
        })
        .catch(function (error) {
          //TODO implement errors
          console.log(error)
          reject(error)
        })
    })
  },
  updateItemsOrder({ commit, rootState }, payload) {
    commit('updateItemsOrderAtId', {
      id: payload.id,
      value: payload.orderedArray.map((itemObject) => itemObject.id),
    })

    //Also update the order on the server
    let categories = []
    categories.push({
      [payload.id]: payload.orderedArray.map((itemObject) => ({
        id: itemObject.id,
      })),
    })

    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/categories/items`,
          { categories: categories }
        )
        .then(function (response) {
          console.log(response)
          toast.success('Items order updated')
          resolve(response)
        })
        .catch(function (error) {
          //TODO implement errors
          console.log(error)
          reject(error)
        })
    })
  },
  updateOptionItemsOrder({ commit, rootState }, payload) {
    commit('updateOptionItemsOrderAtId', {
      id: payload.id,
      value: payload.orderedArray.map(
        (optionItemObject) => optionItemObject.id
      ),
    })

    //Also update the order on the server
    let optionItems = []
    optionItems.push({
      [payload.id]: payload.orderedArray.map((optionItemObject) => ({
        id: optionItemObject.id,
      })),
    })

    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/options/items`,
          { options: optionItems }
        )
        .then(function (response) {
          console.log(response)
          toast.success('Options order updated')
          resolve(response)
        })
        .catch(function (error) {
          //TODO implement errors
          console.log(error)
          reject(error)
        })
    })
  },
  getMenu({ commit, rootState }) {
    // // Use 'jwt' from localstorage as prefix:
    // const jwt = localStorage.getItem('jwt').substring(0, 10);
    // const key = `${jwt}:cache:getMenu:${rootState.auth.restaurantId}`;

    // // If it's already in localStorage then we don't need to fetch it again:
    // if (jwt && localStorage.getItem(key)) {
    //   console.log('Loading cached menus');

    //   return new Promise((resolve, reject) => {
    //     const data = JSON.parse(localStorage.getItem(key));

    //     commit('setMenu', data)
    //     commit('menuHasLoaded', true);
    //     commit('menuIsLoading', false);
    //     resolve({ data: data });
    //   });
    // }

    commit('menuHasLoaded', false)
    commit('menuIsLoading', true)
    return new Promise((resolve, reject) => {
      axios
        .get(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}`
        )
        .then(function (response) {
          // console.log(response);

          let cardsMenusMap = new Map()
          response.data.menus.forEach((menu) => {
            menu.cards.forEach((card) => {
              cardsMenusMap.set(card, [
                ...(cardsMenusMap.get(card) || []),
                menu,
              ])
            })
          })
          // console.log(cardsMenusMap)

          let categoriesCardsMap = new Map()
          response.data.cards.forEach((card) => {
            card.menus = cardsMenusMap.get(card.id) || []
            card.categories.forEach((category) => {
              categoriesCardsMap.set(category, [
                ...(categoriesCardsMap.get(category) || []),
                card,
              ])
            })
          })
          // console.log(categoriesCardsMap)

          let itemsCategoriesMap = new Map()
          response.data.categories.forEach((category) => {
            category.cards = categoriesCardsMap.get(category.id) || []
            category.items.forEach((item) => {
              itemsCategoriesMap.set(item, [
                ...(itemsCategoriesMap.get(item) || []),
                category,
              ])
            })
          })
          // console.log(itemsCategoriesMap)

          let optionGroupsItemsMap = new Map()
          response.data.items.forEach((item) => {
            item.categories = itemsCategoriesMap.get(item.id) || []
            item.option_groups.forEach((optionGroup) => {
              optionGroupsItemsMap.set(optionGroup, [
                ...(optionGroupsItemsMap.get(optionGroup) || []),
                item,
              ])
            })
          })
          // console.log(optionGroupsItemsMap)

          let itemsOptionGroupsMap = new Map()
          response.data.option_groups.forEach((optionGroup) => {
            optionGroup.parentItem =
              optionGroupsItemsMap.get(optionGroup.id) || []
            optionGroup.items.forEach((item) => {
              itemsOptionGroupsMap.set(item, [
                ...(itemsOptionGroupsMap.get(item) || []),
                optionGroup.id,
              ])
            })
          })
          // console.log(itemsOptionGroupsMap)

          response.data.items.forEach((item) => {
            item.optionGroupParent = itemsOptionGroupsMap.get(item.id) || []
          })

          // localStorage.setItem(key, JSON.stringify(response.data));

          commit('setMenu', response.data)
          commit('menuHasLoaded', true)
          commit('menuIsLoading', false)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          commit('menuIsLoading', false)
          reject(error)
        })
    })
  },
  getMenuIfUndefined({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      if (!state.menuHasLoaded && !state.menuIsLoading) {
        dispatch('getMenu')
          .then(function (res) {
            resolve(res)
          })
          .catch(function (err) {
            reject(err)
          })
      } else {
        resolve(null)
      }
    })
  },
  searchInactiveItems({ commit, rootState }, query) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/inactive`,
          {
            params: {
              query: query,
            },
          }
        )
        .then(function (response) {
          resolve(response.data.items)
          response.data.items.forEach((item) => {
            if (
              !state.menu.items.some((stateItem) => stateItem.id === item.id)
            ) {
              //Only commit the inactive items if they weren't already within active items in Orderli
              commit('addInactiveItemToStore', item)
            }
          })
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  getInactiveItems({ state, commit, rootState }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/inactive`
        )
        .then(function (response) {
          //Also add categories and option Groups to inactive items
          //TODO sanetize this code, a lot is now copy paste and duplicate code
          let itemsCategoriesMap = new Map()
          state.menu.categories.forEach((category) => {
            category.items.forEach((item) => {
              itemsCategoriesMap.set(item, [
                ...(itemsCategoriesMap.get(item) || []),
                category,
              ])
            })
          })

          let itemsOptionGroupsMap = new Map()
          state.menu.option_groups.forEach((optionGroup) => {
            optionGroup.items.forEach((item) => {
              itemsOptionGroupsMap.set(item, [
                ...(itemsOptionGroupsMap.get(item) || []),
                optionGroup.id,
              ])
            })
          })

          response.data.items.forEach((item) => {
            item.categories = itemsCategoriesMap.get(item.id) || []
            item.optionGroupParent = itemsOptionGroupsMap.get(item.id) || []
          })

          commit('setInactiveItems', response.data.items)
          commit('inactiveItemsHaveLoaded', true)
          commit('inactiveItemsIsLoading', false)
          resolve(response.data.items)
        })
        .catch(function (error) {
          console.log(error)
          commit('inactiveItemsIsLoading', false)
          reject(error)
        })
    })
  },
  getInactiveItemsIfUndefined({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      console.log(
        `if !${state.inactiveItemsHaveLoaded} && !${state.inactiveItemsIsLoading}`
      )
      if (!state.inactiveItemsHaveLoaded && !state.inactiveItemsIsLoading) {
        dispatch('getInactiveItems')
          .then((res) => {
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      } else {
        resolve(state.inactiveItems)
      }
    })
  },
  getItemTypes({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/restaurants/${rootState.auth.restaurantId}/item-types`
        )
        .then(function (response) {
          commit('setItemTypes', response.data.item_types)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          commit('menuIsLoading', false)
          reject(error)
        })
    })
  },
  getItemTypesIfUndefined({ state, dispatch }) {
    return new Promise((resolve, reject) => {
      if (!state.itemTypes) {
        dispatch('getItemTypes')
          .then(function (res) {
            resolve(res)
          })
          .catch(function (err) {
            reject(err)
          })
      } else {
        resolve('done')
      }
    })
  },
  createItemType({ commit, rootState }, itemType) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/restaurants/${rootState.auth.restaurantId}/item-types`,
          itemType
        )
        .then(function (response) {
          commit('addItemType', response.data)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  updateItemType({ commit, rootState }, itemType) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/restaurants/${rootState.auth.restaurantId}/item-types`,
          { item_types: [itemType] }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  getMenuChangelog({ commit, rootState }, params) {
    let paramsString = ''
    if (params) {
      paramsString = `&${params}`
    }
    return new Promise((resolve, reject) => {
      axios
        .get(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/changes?page=1&results=100${paramsString}`
        )
        .then(function (response) {
          console.log(response)

          // alert('got changes');
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  newOptionGroup({ commit, rootState }, optionGroup) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/options`,
          optionGroup
        )
        .then(function (response) {
          console.log(response)
          toast.success(`Option Group ${optionGroup.name} Created`)
          commit('addOptionGroupToStore', response.data.option)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  updateOptionGroup({ commit, rootState, getters }, optionGroupArray) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/options`,
          { options: optionGroupArray }
        )
        .then(function (response) {
          const optionGroupObjects = optionGroupArray.map((optionGroup) =>
            getters.optionGroupsMap.get(optionGroup.id)
          )
          const optionGroupNames = optionGroupObjects
            .map((optionGroup) => optionGroup.name)
            .join(', ')
          const notificationTitle =
            optionGroupArray.length > 1
              ? `Option Groups ${optionGroupNames} updated`
              : `Option Group ${optionGroupNames} updated`
          toast.success(notificationTitle)
          optionGroupArray.forEach(function (optionGroup) {
            commit('updateOptionGroupInStore', optionGroup)
          })
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteOptionGroup({ commit, rootState, getters }, optionGroupId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/options/${optionGroupId}`
        )
        .then(function (response) {
          const optionGroupName =
            getters.optionGroupsMap.get(optionGroupId)?.name
          toast.success(`Option Group ${optionGroupName} Deleted`)
          commit('removeOptionGroupFromStore', optionGroupId)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  newItem({ commit, rootState }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/items`,
          item
        )
        .then(function (response) {
          console.log(response)
          toast.success(`Item ${item.name} Created`)
          commit('addItemToStore', response.data.item)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  updateItem({ commit, rootState, state, getters }, itemsArray) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/items`,
          { items: itemsArray }
        )
        .then(function (response) {
          const itemObjects = itemsArray.map((item) =>
            getters.itemsAndOptionsMap.get(item.id)
          )
          const itemNames = itemObjects.map((item) => item?.name).join(', ')
          const notificationTitle =
            itemsArray.length > 1
              ? `Items ${itemNames} updated`
              : `Item ${itemNames} updated`
          toast.success(notificationTitle)
          itemsArray.forEach(function (item) {
            //Firstly, update the changes to the item in the store. To makes sure that the changes are also applied in the inactive items store array
            commit('updateItemInStore', item)

            if (item.active === false) {
              let itemObj = state.menu.items.find(
                (result) => item.id === result.id
              )
              if (itemObj) {
                commit('addInactiveItemToStore', itemObj)
              }
              if (
                itemObj &&
                (itemObj.categories === null ||
                  itemObj.categories === undefined ||
                  itemObj.categories.length === 0)
              ) {
                commit('removeItemFromStore', item.id)
              }
            }
          })
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  updateInactiveItem({ commit, rootState, state, getters }, itemsArray) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/items`,
          { items: itemsArray }
        )
        .then(function (response) {
          const itemObjects = itemsArray.map((item) =>
            getters.itemsAndOptionsMap.get(item.id)
          )
          const itemNames = itemObjects.map((item) => item?.name).join(', ')
          const notificationTitle =
            itemsArray.length > 1
              ? `Inactive items ${itemNames} updated`
              : `Inactive item ${itemNames} updated`
          toast.success(notificationTitle)
          itemsArray.forEach(function (item) {
            //Firstly, update the inactive item in the store just to be sure (same as normal PUT call for items)
            commit('updateInactiveItemInStore', item)

            if (item.active === true) {
              //Item has been set to active
              item.active = true
              let itemObj = state.inactiveItems.find(
                (result) => item.id === result.id
              )
              // if(itemObj === undefined) itemObj = state.menu.items.find(result => item.id === result.id)
              console.log(itemObj) //Deze is undefined!!
              if (
                itemObj &&
                (itemObj.categories === null ||
                  itemObj.categories === undefined ||
                  itemObj.categories.length === 0)
              ) {
                commit('addItemToStore', itemObj)
              } else {
                //If itemObj was undefined the item was not in inactive items store, update the item in the normal store
                commit('updateItemInStore', item)
              }
              commit('deleteInactiveItemFromStore', item)
            } else {
            }
          })
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteInactiveItem({ commit, rootState, state, getters }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/items/${item.id}`
        )
        .then(function (response) {
          const itemName = getters.itemsAndOptionsMap.get(item.id)?.name
          toast.success(`Inactive item ${itemName} Deleted`)
          commit('deleteInactiveItemFromStore', item)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteItem({ commit, rootState, getters }, itemId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/items/${itemId}`
        )
        .then(function (response) {
          const itemName = getters.itemsAndOptionsMap.get(itemId)?.name
          toast.success(`Item ${itemName} Deleted`)
          commit('removeItemFromStore', itemId)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  newRecommendation({ commit, rootState }, recommendation) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/recommendations`,
          recommendation
        )
        .then(function (response) {
          console.log(response)
          toast.success('Recommendation Added')
          commit('addRecommendationToStore', response.data.recommendation)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteRecommendation({ commit, rootState }, recommendation) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/recommendations/${recommendation.item_id}/${recommendation.recommendation_id}`
        )
        .then(function (response) {
          console.log(response)
          toast.success('Recommendation Deleted')
          commit('removeRecommendationFromStore', recommendation)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  newCategory({ commit, rootState }, category) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/categories`,
          category
        )
        .then(function (response) {
          console.log(response)
          toast.success(`Category ${category.name} Created`)
          commit('addCategoryToStore', response.data.category)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  updateCategory({ commit, rootState, getters }, categoryArray) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/categories`,
          { categories: categoryArray }
        )
        .then(function (response) {
          const categoryObjects = categoryArray.map((category) =>
            getters.categoriesMap.get(category.id)
          )
          const categoryNames = categoryObjects
            .map((category) => category.name)
            .join(', ')
          const notificationTitle =
            categoryArray.length > 1
              ? `Categories ${categoryNames} updated`
              : `Category ${categoryNames} updated`
          toast.success(notificationTitle)
          categoryArray.forEach(function (category) {
            commit('updateCategoryInStore', category)
          })
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteCategory({ commit, rootState, getters }, categoryId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/categories/${categoryId}`
        )
        .then(function (response) {
          const categoryName = getters.categoriesMap.get(categoryId)?.name
          toast.success(`Category ${categoryName} Deleted`)
          commit('removeCategoryFromStore', categoryId)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  newCard({ commit, rootState }, card) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/cards`,
          card
        )
        .then(function (response) {
          console.log(response)
          toast.success(`Card ${card.name} Created`)
          commit('addCardToStore', response.data.card)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  updateCard({ commit, rootState, getters }, cardArray) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/cards`,
          { cards: cardArray }
        )
        .then(function (response) {
          const cardObjects = cardArray.map((card) =>
            getters.cardsMap.get(card.id)
          )
          const cardNames = cardObjects.map((card) => card.name).join(', ')
          const notificationTitle =
            cardArray.length > 1
              ? `Cards ${cardNames} updated`
              : `Card ${cardNames} updated`
          
          toast.success(notificationTitle)
          
          cardArray.forEach(function (card) {
            commit('updateCardInStore', card)
          })
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteCard({ commit, rootState, getters }, cardId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/cards/${cardId}`
        )
        .then(function (response) {
          const cardName = getters.cardsMap.get(cardId)?.name
          toast.success(`Card ${cardName} Deleted`)
          commit('removeCardFromStore', cardId)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  newMenu({ commit, rootState }, menu) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/menus`,
          menu
        )
        .then(function (response) {
          toast.success(`Menu ${menu.name} Created`)
          commit('addMenuToStore', response.data.menu)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  updateMenu({ commit, rootState, getters }, menuObj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/menus/${menuObj.id}`,
          menuObj
        )
        .then(function (response) {
          const menuName = getters.menusMap.get(menuObj.id)?.name
          toast.success(`Menu ${menuName} Updated`)
          commit('updateMenuInStore', menuObj)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteMenu({ commit, rootState, getters }, menuId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/menus/${menuId}`
        )
        .then(function (response) {
          const menuName = getters.menusMap.get(menuId)?.name
          toast.success(`Menu ${menuName} Deleted`)
          commit('removeMenuFromStore', menuId)
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  searchPos({ rootState }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/search-pos`,
          { is_option: data['is_option'], query: data['query'] }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  searchUntappd({ rootState }, query) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/search-untappd`,
          { query: query }
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },
  async getPosItems({ commit, rootGetters, rootState }, params) {
    let restaurantId = rootState.auth.restaurantId

    let append = ''
    if (params) {
      //TODO hacky mooiere oplossing van axios zelf hier ook doen
      append = `?page=${params.page}&results=${params.results}`
    }

    // Already load all potential caches from localForage
    // This needs async await because this needs to be completed to determine if there was even a cache saved
    const cache = await localForage.getItem(`${restaurantId}:cache:allPosItems`)
    const lastFetched = await localForage.getItem(
      `${restaurantId}:cache:allPosItemsTime`
    )

    return new Promise((resolve, reject) => {
      // Check if the query was to retrieve all POS items
      const getAllPosItems = params && params.results === 10000

      // Check if there was a cache saved by localForage and if the cache is still newer than the last pos import time
      // Use the getters from the root Vuex store to retrieve the restaurant, read the pos_imported_at property from there
      // pos_imported_at should be a string, so wrap it in a new Date() and then use getTime for easy comparison with lastFetched
      // Only fetch it for admins to prevent issues for customers
      // because they might still get a cached restaurant that can have an outdated pos_imported_at value
      const validCache =
        cache &&
        lastFetched >
          new Date(
            rootGetters.currentRestaurant.pos_imported_at + 'Z'
          ).getTime()
      if (getAllPosItems && validCache && rootGetters.isAdmin) {
        console.log('retrieved pos items from cache')
        const cachedResponse = { data: cache }
        resolve(cachedResponse)
        commit('setPosItems', cache.data)
      } else {
        axios
          .get(
            `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${restaurantId}/pos-items${append}`
          )
          .then(function (response) {
            //Also save the response and the current time to save locally using localForage
            if (getAllPosItems && rootGetters.isAdmin) {
              // only save to localForage if the request was for 10000 items
              // Currently only save it for admins to prevent issues for customers
              // because they might still get a cached restaurant that can have an outdated pos_imported_at value
              localForage.setItem(
                `${restaurantId}:cache:allPosItems`,
                response.data
              )
              localForage.setItem(
                `${restaurantId}:cache:allPosItemsTime`,
                new Date().getTime()
              )
            }

            resolve(response)
            commit('setPosItems', response.data.data)
          })
          .catch(function (error) {
            console.log(error)
            reject(error)
          })
      }
    })
  },
  getVatPercentages({ rootState }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/vat-percentages`
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },
  updateVatPercentages({ rootState }, obj) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/vat-percentages`,
          obj
        )
        .then(function (response) {
          resolve(response)
        })
        .catch(function (err) {
          reject(err)
        })
    })
  },
  getUntillSalesAreas({ rootState }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `dashboard/api/v1/accounts/${rootState.auth.accountId}/menu/${rootState.auth.restaurantId}/untill-salesareas`
        )
        .then(function (res) {
          console.log(res)
          resolve(res)
        })
        .catch(function (err) {
          console.log(err)
          reject(err)
        })
    })
  },
}

export const mutations = {
  setItemModal(state, payload) {
    state.showItemModalId = payload
  },
  updateCardsOrderAtId(state, payload) {
    console.log(payload)
    state.menu.menus.find((menu) => menu.id === payload.id).cards =
      payload.value
  },
  updateCategoriesOrderAtId(state, payload) {
    console.log(payload)
    state.menu.cards.find((card) => card.id === payload.id).categories =
      payload.value
  },
  updateItemsOrderAtId(state, payload) {
    console.log(payload)
    state.menu.categories.find((category) => category.id === payload.id).items =
      payload.value
  },
  updateOptionItemsOrderAtId(state, payload) {
    console.log(payload)
    state.menu.option_groups.find(
      (optionGroup) => optionGroup.id === payload.id
    ).items = payload.value
  },
  setMenu(state, menu) {
    state.menu = menu //This is quicker (4sec vs 6.5sec) but might break Vue reactivity on the menu, or not idk

    // Vue.set(state, 'menu', menu);

    // Object.keys(menu).forEach(function(prop){
    //   state.menu[prop] = menu[prop];
    // })
  },
  menuHasLoaded(state, value) {
    state.menuHasLoaded = value
  },
  menuIsLoading(state, value) {
    state.menuIsLoading = value
  },
  setInactiveItems(state, items) {
    console.log(items)
    state.inactiveItems = items
  },
  inactiveItemsIsLoading(state, value) {
    state.inactiveItemsIsLoading = value
  },
  inactiveItemsHaveLoaded(state, value) {
    state.inactiveItemsHaveLoaded = value
  },
  addInactiveItemToStore(state, item) {
    console.log('Add to inactive item: ' + item)
    let foundIndex = state.inactiveItems.findIndex((x) => x.id === item.id)
    if (foundIndex === -1) {
      //Only add the item if the item wasn't already in the inactive items store
      state.inactiveItems.push(item)
    }
  },
  updateInactiveItemInStore(state, item) {
    let foundIndex = state.inactiveItems.findIndex((x) => x.id === item.id)
    if (foundIndex >= 0) {
      Object.keys(item).forEach(function (prop) {
        state.inactiveItems[foundIndex][prop] = item[prop]
      })
    } else {
      //TODO sentry log for this because this shouldn't happen
    }
  },
  deleteInactiveItemFromStore(state, item) {
    let foundIndex = state.inactiveItems.findIndex((x) => x.id === item.id)
    if (foundIndex >= 0) {
      state.inactiveItems.splice(foundIndex, 1)
    } else {
      //TODO sentry log for this because this shouldn't happen
    }
  },
  setItemTypes(state, itemTypes) {
    state.itemTypes = itemTypes.sort((a, b) => a.id - b.id)
  },
  addItemType(state, itemType) {
    state.itemTypes.push(itemType)
  },
  addItemToStore(state, item) {
    state.menu.items.push(item)
  },
  updateItemInStore(state, item) {
    let foundIndex = state.menu.items.findIndex((x) => x.id === item.id)
    if (foundIndex === -1) {
      foundIndex = state.inactiveItems.findIndex((x) => x.id === item.id)
    } else {
      Object.keys(item).forEach(function (prop) {
        state.menu.items[foundIndex][prop] = item[prop]
      })
      if (state.menu.recommendations) {
        // update cross sell recommendations to active/inactive
        let item_index = state.menu.recommendations.findIndex(
          (x) => x.item_id === item.id
        )
        if (item_index >= 0) {
          let cached_recommendations =
            state.menu.recommendations[item_index].recommendations
          cached_recommendations.forEach(function (cached_recommendation) {
            cached_recommendation.active = state.menu.items[
              foundIndex
            ].recommendations.includes(cached_recommendation.item_id)
          })
        }
      }
    }
  },
  removeItemFromStore(state, id) {
    let index = state.menu.items.findIndex((item) => item.id === id)
    state.menu.items.splice(index, 1)
  },
  addRecommendationToStore(state, recommendation) {
    let item_index = state.menu.recommendations.findIndex(
      (x) => x.item_id === recommendation.item_id
    )
    let recommendation_index = state.menu.recommendations[
      item_index
    ].recommendations.findIndex(
      (x) => x.item_id === recommendation.recommendation_id
    )
    state.menu.recommendations[item_index].recommendations[
      recommendation_index
    ].id = recommendation.id

    item_index = state.menu.items.findIndex(
      (item) => item.id === recommendation.item_id
    )
    state.menu.items[item_index].recommendations.push(
      recommendation.recommendation_id
    )
  },
  removeRecommendationFromStore(state, recommendation) {
    let item_index = state.menu.items.findIndex(
      (item) => item.id === recommendation.item_id
    )
    let recommendation_index = state.menu.items[
      item_index
    ].recommendations.indexOf(recommendation.recommendation_id)
    state.menu.items[item_index].recommendations.splice(recommendation_index, 1)
  },
  addOptionGroupToStore(state, option_group) {
    state.menu.option_groups.push(option_group)
  },
  updateOptionGroupInStore(state, option_group) {
    let foundIndex = state.menu.option_groups.findIndex(
      (x) => x.id === option_group.id
    )
    Object.keys(option_group).forEach(function (prop) {
      if (prop !== 'overrides') {
        state.menu.option_groups[foundIndex][prop] = option_group[prop]
      }
    })

    // Use different logic to update option overrides, as the PUT call is different than GET call format, so we change it back
    if (option_group.overrides && option_group.overrides.for_item_id) {
      // GET format: overrides: [{item_id, option_group_id, option_item_id, price}, ...]
      // PUT format: overrides: {for_item_id, overrides: [{option_item_id, price}, ...]}

      const forItemId = option_group.overrides.for_item_id
      const existingOverrides =
        state.menu.option_groups[foundIndex]['overrides'] || []
      const newOverrides = option_group.overrides.overrides

      // Keep overrides for other items
      const updatedOverrides = existingOverrides.filter(
        (o) => o.item_id !== forItemId
      )

      // Update or add new overrides
      newOverrides.forEach((newOverride) => {
        if (newOverride.price !== null) {
          updatedOverrides.push({
            item_id: forItemId,
            option_group_id: option_group.id,
            option_item_id: newOverride.option_item_id,
            price: newOverride.price,
          })
        }
      })

      // Update the state for option group
      state.menu.option_groups[foundIndex].overrides = updatedOverrides
      state.menu.items[foundIndex].has_option_overrides =
        updatedOverrides.length > 0

      // Update the state for the item for which the overrides were set, as they are also passed on item level by the backend currently
      let overridesForCurrentItem = updatedOverrides.filter(
        (override) => override.item_id === forItemId
      )
      this.commit('updateItemInStore', {
        id: forItemId,
        option_overrides: overridesForCurrentItem,
      })
    }
  },
  removeOptionGroupFromStore(state, id) {
    let index = state.menu.option_groups.findIndex(
      (option_group) => option_group.id === id
    )
    state.menu.option_groups.splice(index, 1)
  },
  addCategoryToStore(state, category) {
    state.menu.categories.push(category)
  },
  updateCategoryInStore(state, category) {
    let foundIndex = state.menu.categories.findIndex(
      (x) => x.id === category.id
    )
    Object.keys(category).forEach(function (prop) {
      state.menu.categories[foundIndex][prop] = category[prop]
    })
  },
  removeCategoryFromStore(state, id) {
    let index = state.menu.categories.findIndex(
      (category) => category.id === id
    )
    state.menu.categories.splice(index, 1)
  },
  addCardToStore(state, card) {
    state.menu.cards.push(card)
  },
  updateCardInStore(state, card) {
    let foundIndex = state.menu.cards.findIndex((x) => x.id === card.id)
    Object.keys(card).forEach(function (prop) {
      state.menu.cards[foundIndex][prop] = card[prop]
    })
  },
  removeCardFromStore(state, id) {
    let index = state.menu.cards.findIndex((card) => card.id === id)
    // Vue.set(state.menu.cards, index);
    state.menu.cards.splice(index, 1)
  },
  addMenuToStore(state, menu) {
    state.menu.menus.push(menu)

    // Vue.set(state.menu, 'menus', menu)

    // let foundIndex = state.menu.menus.findIndex(x => x.id === menu.id);
    // Object.keys(state.menu).forEach(function(prop){
    //   state.menu.menus[foundIndex][prop] = menu[prop];
    // })
  },
  updateMenuInStore(state, menu) {
    let foundIndex = state.menu.menus.findIndex((x) => x.id === menu.id)
    Object.keys(menu).forEach(function (prop) {
      state.menu.menus[foundIndex][prop] = menu[prop]
    })
  },
  removeMenuFromStore(state, id) {
    let index = state.menu.menus.findIndex((menu) => menu.id === id)
    state.menu.menus.splice(index, 1)
  },
  setPosItems(state, items) {
    state.posItems = items
  },
}
