<template>
  <div>
    <div v-if="showFilter" class="mx-4 mb-4">
      <Button @click="applyFilter">Apply</Button>
      <Button @click="resetFilter">Reset</Button>
    </div>

    <div v-if="menuIsLoading" class="flex justify-center py-8">
      <div class="loader"></div>
    </div>

    <div v-else-if="recommendations.length === 0" class="text-center py-8">
      <p class="text-lg font-semibold text-gray-600">No recommendations available</p>
      <p class="text-sm text-gray-500 mt-2">Try adjusting your filters or add some recommendations to see them here.</p>
    </div>

    <div v-else class="border rounded-md">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead v-for="column in columns" :key="column.accessorKey">
              {{ column.header }}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow v-for="row in recommendations" :key="row.item_id">
            <TableCell>
              <div class="flex items-center">
                <Avatar class="mr-3">
                  <AvatarImage
                    :src="`https://orderli.com/cdn-cgi/image/width=100,quality=60/https://imagedelivery.net/xS_5nksgKmcoB2_mcBGUmA/${row.item_image}`"
                    :alt="row.item_image"
                    @error="placeholderImgUrl"
                  />
                </Avatar>
                <div>
                  <h5 class="font-semibold text-sm">{{ row.item_name }}</h5>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div class="space-y-4 py-2">
                <div v-for="recommendation in row.recommendations" :key="recommendation.item_id" class="flex items-center">
                  <Avatar class="mr-3">
                    <AvatarImage
                      :src="`https://orderli.com/cdn-cgi/image/width=100,quality=60/https://imagedelivery.net/xS_5nksgKmcoB2_mcBGUmA/${recommendation.item_image}`"
                      :alt="recommendation.item_image"
                      @error="placeholderImgUrl"
                    />
                  </Avatar>
                  <div>
                    <h5 class="font-semibold text-sm">{{ recommendation.item_name }}</h5>
                    <p class="text-sm">
                      {{ recommendation.combination_count }}x combined ({{ recommendation.combination_percentage }}% of orders)
                    </p>
                  </div>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div class="space-y-4 py-2">
                <div v-for="recommendation in row.recommendations" :key="recommendation.item_id">
                  <Switch
                    v-model="recommendation.active"
                    @update:modelValue="(event) => toggleActive(event, row.item_id, recommendation)"
                  />
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>

    <Button
      v-if="recommendations.length >= 2"
      class="mt-3"
      @click="$router.push('/menu/recommendations')"
    >
      Show more
    </Button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Button } from '@/components/ui/button'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Switch } from '@/components/ui/switch'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

const props = defineProps({
  showFilter: Boolean,
  showCompact: Boolean,
  inactiveProducts: Array,
})

const store = useStore()
const router = useRouter()

const multipleSelection = ref([])
const filterFunction = ref('item => true')
const appliedFilterFunction = ref((item: any) => true)

const columns = [
  { accessorKey: 'product', header: 'Product' },
  { accessorKey: 'recommendations', header: 'Recommendations' },
  { accessorKey: 'active', header: 'Active' },
]

const optionGroupsMap = computed(() => store.getters.optionGroupsMap)
const menuIsLoading = computed(() => store.getters.menuIsLoading)
const inactiveItemsIsLoading = computed(() => store.getters.inactiveItemsIsLoading)
const currentRestaurant = computed(() => store.getters.currentRestaurant)

const recommendations = computed(() => {
  const allRecommendations = store.getters.recommendationsOfCurrentRestaurant.filter(appliedFilterFunction.value)
  return props.showCompact ? allRecommendations.slice(0, 2) : allRecommendations
})

const noRecommendationsAccess = computed(() => store.getters.recommendationsNotSupportedForSubscription)

function toggleActive(event: boolean, item_id: string, recommendation: any) {
  if (event) {
    const payload = {
      restaurant_id: currentRestaurant.value.id,
      item_id: item_id,
      recommendation_id: recommendation.item_id,
    }
    store.dispatch('newRecommendation', payload)
      .then(() => console.log('recommendation added'))
      .catch((err) => alert(err))
  } else {
    const payload = {
      item_id: item_id,
      recommendation_id: recommendation.item_id,
    }
    store.dispatch('deleteRecommendation', payload)
      .then(() => console.log('recommendation deleted'))
      .catch((err) => alert(err))
  }
}

function handleSelectionChange(val: any) {
  multipleSelection.value = val
}

function placeholderImgUrl(event: Event) {
  const placeholderSrc = '/img/menu/image-placeholder-350x350.png'
  if ((event.target as HTMLImageElement).currentSrc !== placeholderSrc) {
    (event.target as HTMLImageElement).src = placeholderSrc
  }
}

function applyFilter() {
  appliedFilterFunction.value = eval(filterFunction.value)
}

function resetFilter() {
  filterFunction.value = 'item => true'
  appliedFilterFunction.value = (item: any) => true
}
</script>

<style scoped>
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid hsl(var(--orderli));
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>