<template>
  <Transition name="fade">
    <div
      v-if="visible"
      class="alert"
      :class="[`alert-${type}`, { 'alert-dismissible': dismissible }]"
      role="alert"
    >
      <template v-if="icon || $slots.icon">
        <slot name="icon">
          <span class="alert-icon" data-notify="icon">
            <i :class="icon"></i>
          </span>
        </slot>
      </template>
      <slot v-if="!dismissible"></slot>
      <template v-else>
        <span class="alert-text"> <slot></slot> </span>

        <slot name="dismiss-icon">
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="dismissAlert"
          >
            <span aria-hidden="true">×</span>
          </button>
        </slot>
      </template>
    </div>
  </Transition>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  type: {
    type: String,
    default: 'default',
  },
  dismissible: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
})

const visible = ref(true)

const dismissAlert = () => {
  visible.value = false
}
</script>
