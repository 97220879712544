import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
// import Card from '@/components/Cards/Card.vue'
// import Modal from '@/components/Modal.vue'
// import StatsCard from '@/components/Cards/StatsCard.vue'
import BaseButton from '@/components/BaseButton.vue'
// import Badge from '@/components/Badge.vue'
// import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import BaseSwitch from '@/components/BaseSwitch.vue'
import BaseRadio from '@/components/Inputs/BaseRadio'
import BaseProgress from '@/components/BaseProgress'
import BasePagination from '@/components/BasePagination'
import BaseAlert from '@/components/BaseAlert'
// import BaseNav from '@/components/Navbar/BaseNav'
import BaseHeader from '@/components/BaseHeader'
import InfoIcon from '@/components/InfoIcon.vue'
import IsAdmin from '@/components/IsAdmin.vue'
import Only from '@/components/Only.vue'
import UnlockFeature from '@/components/UnlockFeature.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { Form as ValidationObserver, Field as ValidationProvider } from 'vee-validate'
import {
  ElInput,
  ElTooltip,
  // ElPopover,
  ElSelect,
  ElTimePicker,
  ElOption,
  ElOptionGroup,
} from 'element-plus'
// import VueTippy from 'vue-tippy'
// import 'tippy.js/dist/tippy.css'
// import 'tippy.js/animations/scale.css'
// import 'tippy.js/themes/light.css'

// Fixes an issue with filters not working on mobile
// Select.computed.readonly = function () {
//   // trade-off for IE input readonly problem: https://github.com/ElemeFE/element/issues/10403
//   const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));

//   return !(this.filterable || this.multiple || !isIE) && !this.visible;
// };


/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(app) {
    // app.component(Badge.name, Badge)
    app.component(BaseAlert.name, BaseAlert)
    app.component(BaseButton.name, BaseButton)
    app.component(BaseCheckbox.name, BaseCheckbox)
    app.component(BaseHeader.name, BaseHeader)
    app.component(BaseInput.name, BaseInput)
    app.component(BaseDropdown.name, BaseDropdown)
    // app.component(BaseNav.name, BaseNav)
    app.component(BasePagination.name, BasePagination)
    app.component(BaseProgress.name, BaseProgress)
    app.component(BaseRadio.name, BaseRadio)
    app.component(BaseSwitch.name, BaseSwitch)
    // app.component(Card.name, Card)
    // app.component(Modal.name, Modal)
    app.component('InfoIcon', InfoIcon) // Register InfoIcon without using .name
    // app.component(StatsCard.name, StatsCard)
    app.component(IsAdmin.name, IsAdmin)
    app.component(Only.name, Only)
    app.component(UnlockFeature.name, UnlockFeature)
    app.component(CurrencyInput.name, CurrencyInput)
    // app.component(RouteBreadcrumb.name, RouteBreadcrumb)
    app.component(ElInput.name, ElInput)
    app.component('ValidationProvider', ValidationProvider)
    app.component('ValidationObserver', ValidationObserver)
    app.use(ElTooltip)
    // app.use(ElPopover)
    app.use(ElSelect)
    app.use(ElOption)
    app.use(ElTimePicker)
    app.use(ElOptionGroup)
    // app.use(VueTippy, {
    //   directive: 'tippy', // => v-tippy
    //   component: 'tippy', // => <tippy/>
    //   componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    //   defaultProps: {
    //     placement: 'auto-end',
    //     allowHTML: true,
    //   },
    // })
  },
}

export default GlobalComponents