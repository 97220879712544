<template>
  <div v-if="isAdmin">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'IsAdmin',

  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
})
</script>
