<template>
  <component
    :is="tag"
    :class="[{ show: isOpen }, `drop${direction}`]"
    @click="toggleDropDown"
    @mouseover="toggleDropDownHover"
    @mouseleave="closeDropDownHover"
    v-click-outside="closeDropDown"
  >
    <slot name="title-container" :is-open="isOpen">
      <component
        :is="titleTag"
        class="btn-rotate"
        :class="[{ 'dropdown-toggle': hasToggle }, titleClasses]"
        :aria-expanded="isOpen"
        data-toggle="dropdown"
      >
        <slot name="title" :is-open="isOpen">
          <i :class="icon"></i> {{ title }}
        </slot>
      </component>
    </slot>
    <ul
      class="dropdown-menu"
      :class="[
        { show: isOpen },
        { 'dropdown-menu-right': menuOnRight },
        menuClasses,
      ]"
    >
      <slot></slot>
    </ul>
  </component>
</template>

<script>
import { $on, $off, $once, $emit } from '../utils/gogocodeTransfer'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'base-dropdown',

  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown html tag (e.g div, ul etc)',
    },
    titleTag: {
      type: String,
      default: 'button',
      description: 'Dropdown title (toggle) html tag',
    },
    title: {
      type: String,
      description: 'Dropdown title',
    },
    direction: {
      type: String,
      default: 'down', // up | down
      description: 'Dropdown menu direction (up|down)',
    },
    icon: {
      type: String,
      description: 'Dropdown icon',
    },
    titleClasses: {
      type: [String, Object, Array],
      description: 'Title css classes',
    },
    menuClasses: {
      type: [String, Object],
      description: 'Menu css classes',
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether menu should appear on the right',
    },
    hasToggle: {
      type: Boolean,
      description: 'Whether dropdown has arrow icon shown',
      default: true,
    },
    hover: {
      type: Boolean,
      //Hover now doens't work, as there is gap you cant hover over
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen
      $emit(this, 'change', this.isOpen)
    },
    closeDropDown() {
      this.isOpen = false
      $emit(this, 'change', false)
    },
    toggleDropDownHover() {
      if (this.hover) this.toggleDropDown()
    },
    closeDropDownHover() {
      if (this.hover) this.closeDropDown()
    },
  },
})
</script>

<style lang="scss" scoped>
.dropdown {
  cursor: pointer;
  user-select: none;
}
</style>
