<template>
  <div class="flex items-start space-x-4 p-4 hover:bg-gray-100">
    <AlertCircleIcon class="h-5 w-5 text-yellow-500 mt-0.5" />
    <div>
      <h6 class="text-sm font-medium">{{ cardTitle }}</h6>
      <p class="text-sm text-gray-500">{{ cardDescription }}</p>
      <Button variant="link" size="sm" class="mt-1 p-0" @click="goToLink">
        Go to settings
      </Button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { AlertCircleIcon } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'

const props = defineProps({
  cardTitle: String,
  cardDescription: String,
  cardLink: String,
})

const router = useRouter()

function goToLink() {
  router.push(props.cardLink)
}
</script>

<style>
.card-title {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0.6rem;
  font-size: 0.65rem;
  color: #32325d;
}
.circle-background {
  height: 30px;
  background: #f0c0b2;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 5px hsl(0deg 0% 69% / 23%);
  box-shadow: 1px 1px 5px hsl(0deg 0% 69% / 23%);
  min-width: 30px;
  border: none;
  margin-left: -3px;
  margin-right: 8px;
  padding: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.link:hover {
  background-color: #f6f9fc;
}
.notification-description {
  text-transform: none;
  color: #525f7f;
}
.chevron {
  font-size: 15px;
  padding-right: 5px;
  padding-left: 15px;
  color: white;
}
.link:hover .chevron {
  color: #525f7f;
}
</style>
