<template>
  <nav class="bg-white border-b">
    <div class="mx-auto px-2 sm:px-4">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <Button variant="ghost" size="icon" class="md:hidden mr-2" @click="toggleSidebar">
            <MenuIcon class="h-5 w-5" />
          </Button>
          <div class="flex items-center space-x-1 md:space-x-2"> 
            <Button v-if="isAdmin" variant="ghost" size="icon" @click="reloadWithoutCache" v-tippy="{ content: 'Reload accounts without cache', arrow: true, arrowType: 'round', animation: 'scale', theme: 'light' }">
              <RefreshCwIcon class="h-3.5 w-3.5" /> 
            </Button>
            <nav v-if="currentRestaurant" aria-label="breadcrumb" class="hidden md:block">
              <route-bread-crumb></route-bread-crumb>
            </nav>
            <Button variant="ghost" size="icon" @click="copyCurrentUrl" v-tippy="{ content: 'Copy Link to Current Page', arrow: true, arrowType: 'round', animation: 'scale', theme: 'light' }">
              <LinkIcon class="h-3.5 w-3.5" />
            </Button>
            <Transition>
              <small v-if="copiedToClipboard" class="text-sm text-gray-600">Copied to clipboard</small>
            </Transition>
          </div>
        </div>
        <ul class="flex items-center space-x-2 lg:space-x-3">
          <span v-if="trialDays > 1" class="px-3 py-1 bg-orange-500 rounded text-white text-xs">
            {{ trialDays }} trial days left
          </span>
          <span v-else-if="trialDays === 1" class="px-3 py-1 bg-orange-500 rounded text-white text-xs">
            {{ trialDays }} trial day left
          </span>
          <span v-else-if="trialDays < 1 && trialDays > 0" class="px-3 py-1 bg-orange-500 rounded text-white text-xs">
            {{ Math.round((new Date(currentRestaurant.trial_ends_at).getTime() - new Date().getTime()) / 3600000) }}
            trial hours left
          </span>

          <Select v-if="isAdminOrImpersonating" v-model="adminImpersonating" class="w-auto mr-2">
            <SelectTrigger class="w-auto">
              <SelectValue class="pr-1" />
            </SelectTrigger>
            <SelectContent align="end">
              <SelectItem v-for="role in roles" :key="role.value" :value="role.value">
                View <span class="hidden md:inline-block">dashboard</span> as {{ role.label.toLowerCase() }}
              </SelectItem>
            </SelectContent>
          </Select>

          <NotificationsWrapper v-if="canManageAccounts || role === 'owner'" />

          <DropdownMenu v-model:open="userDropdownOpen">
            <DropdownMenuTrigger as="div">
              <Button variant="ghost" size="icon" class="relative" :class="{ 'bg-accent': userDropdownOpen }">
                <UserIcon class="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent class="w-56" align="end">
              <DropdownMenuLabel class="font-normal">
                <div class="flex flex-col space-y-1">
                  <p class="text-sm font-medium leading-none">{{ nameOfUser }}</p>
                  <p class="text-xs leading-none text-muted-foreground">
                    {{ currentRoleLabel }}
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem @click="userInformationModal(true)">
                <UserIcon class="mr-2 h-4 w-4" />
                <span>Edit profile</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem @click="logout">
                <LogOutIcon class="mr-2 h-4 w-4" />
                <span>Log out</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </ul>
      </div>
    </div>
    <EditUserInformationModal
      :show="showUserInformationModal"
      @modalClose="userInformationModal(false)"
    />
  </nav>
</template>

<script setup>
import { ref, computed, onMounted, watch, inject } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Button } from '@/components/ui/button'
import { MenuIcon, UserIcon, LogOutIcon, RefreshCwIcon, LinkIcon } from 'lucide-vue-next'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue'
import EditUserInformationModal from '@/views/Settings/EditUserInformationModal.vue'
import NotificationsWrapper from '@/components/NotificationBell/NotificationsWrapper.vue'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from '@/components/ui/dropdown-menu'

const store = useStore()
const route = useRoute()

const showUserInformationModal = ref(false)
const trialDays = ref(null)
const copiedToClipboard = ref(false)
const adminImpersonating = ref('admin')
const roles = ref([
  { value: 'admin', label: 'Admin' },
  { value: 'owner', label: 'Owner' },
  { value: 'manager', label: 'Manager' },
  { value: 'employee', label: 'Employee' },
])

const isAdmin = computed(() => store.getters.isAdmin)
const canManageAccounts = computed(() => store.getters.canManageAccounts)
const role = computed(() => store.getters.currentRole)
const mandateGiven = computed(() => store.getters.mandateGiven)
const nameOfUser = computed(() => store.getters.userFullName)
const currentRestaurant = computed(() => store.getters.currentRestaurant)
const accountId = computed(() => store.getters.currentAccountId)
const restaurantId = computed(() => store.getters.currentRestaurantId)
const currentRoleLabel = computed(() => {
  const currentRole = roles.value.find(r => r.value === adminImpersonating.value)
  return currentRole ? currentRole.label.toLowerCase() : 'admin'
})
const isAdminOrImpersonating = computed(() => isAdmin.value || adminImpersonating.value !== 'admin')

const toggleSidebar = inject('toggleSidebar')

const open = ref(false)
const userDropdownOpen = ref(false)

onMounted(() => {
  if (currentRestaurant.value === undefined) {
    const unwatch = watch(() => store.getters.currentRestaurant, (restaurantLoaded) => {
      unwatch()
      if (restaurantLoaded) {
        updateTrialDays()
      }
    })
  } else {
    updateTrialDays()
  }
})

function updateTrialDays() {
  const currentDate = new Date()
  const trialEndDate = new Date(currentRestaurant.value.trial_ends_at)
  const timeDifference = trialEndDate.getTime() - currentDate.getTime()
  trialDays.value = Math.round(timeDifference / (1000 * 3600 * 24))
}

function userInformationModal(value) {
  showUserInformationModal.value = value
  console.log(value)
}

function logout() {
  store.dispatch('logout')
    .then(() => {
      console.log('logout successful')
    })
    .catch((err) => alert(err))
}

function reloadWithoutCache() {
  store.dispatch('clearAccountCache')
  store.dispatch('getAccounts', false)
    .then(() => {
      alert('cleared cache & reload successful, all account data should be up-to-date now')
    })
    .catch((err) => alert(err))
}

function copyCurrentUrl() {
  const accountAndRestaurantString = `accountId=${accountId.value}&restaurantId=${restaurantId.value}`
  const query = window.location.search
    ? `${window.location.search}&${accountAndRestaurantString}`
    : `?${accountAndRestaurantString}`

  navigator.clipboard
    .writeText(
      window.location.origin +
        window.location.pathname +
        query +
        window.location.hash
    )
    .then(
      () => {
        copiedToClipboard.value = true
        setTimeout(() => {
          copiedToClipboard.value = false
        }, 2500)
      },
      (err) => {
        console.error('Could not copy text: ', err)
      }
    )
}

watch(adminImpersonating, (newValue) => {
  store.commit('setAdminImpersonateRole', newValue === 'admin' ? null : newValue)
})

</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>